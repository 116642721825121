const INITIAL_STATE = {};

export default function reduceJackpots (state = INITIAL_STATE, action) {
  if (action.type === 'UPDATE_JACKPOTS') {
    const {
      date,
      jackpot,
      lottery,
      numbers,
      resultsAnnouncedAt,
    } = action.payload;

    let newState = Object.assign({}, state);
    if (date && lottery) {
      if (!newState[lottery]) {
        newState[lottery] = {};
      }
      newState[lottery][date] = {
        jackpot,
        numbers,
        resultsAnnouncedAt
      }
    }
    return newState;
  }
  return state;
}