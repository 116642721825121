import { applyMiddleware, createStore } from "redux"
import thunk from 'redux-thunk'

import reducer from './reducers';

const initialState = { }

export default () => createStore(
  reducer,
  initialState,
  applyMiddleware(thunk)
)

